import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { postGetRefreshToken } from 'src/api/auth';
import {
  getAccessToken,
  getRefreshToken,
  logout
} from 'src/common/authFunction';
import { baseTMSApiUrl } from 'src/constant/baseApiURL';
import { TAuthResponse } from 'src/interface/auth.interface';

const requestConfig: AxiosRequestConfig = {
  baseURL: baseTMSApiUrl,
  headers: {
    tenant: 'root'
  }
};

export const axiosInstance = axios.create(requestConfig);

let getNewAuthenToken: Promise<TAuthResponse> | null = null;

const getNewToken = async (error: AxiosError) => {
  const refreshToken = getRefreshToken();
  const token = getAccessToken();

  // if miss one of token -> throw error immediately
  if (!refreshToken || !token) {
    logout();
    throw error;
  }

  if (!getNewAuthenToken) {
    //save promise to global variable
    getNewAuthenToken = postGetRefreshToken({ refreshToken, token });
  }

  try {
    //make all api calling wait this promise
    const data = await (getNewAuthenToken as Promise<TAuthResponse>);

    if (error.config.headers)
      error.config.headers.Authorization = `Bearer ${data.token}`;

    const newAxios = axios.create();
    //clone interceptors.response of the original axios
    newAxios.interceptors.response.use(
      (res) => res.data,
      (err) => {
        const status = err.response?.status;

        //if 401 error means have s.t wrong with new tokens
        if (status === 401) {
          logout();
        }

        throw err;
      }
    );

    //recall 401 request with new token have been set to header above
    return await newAxios(error.config);
  } catch (_err) {
    logout();

    throw error;
  } finally {
    //release global var store promise
    getNewAuthenToken = null;
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const accessToken = getAccessToken();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (error) => {
    const statusCode = error.response?.status as number;

    if (statusCode == 401) {
      //if global var is store a promise but failed with 401 error, that means refresh token failed, must throw err
      if (getNewAuthenToken) {
        throw error;
      }
      try {
        return await getNewToken(error);
      } catch (err) {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
