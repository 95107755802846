import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getAllGroups } from 'src/api/groups';
import { EQueryKey } from 'src/constant/queryKey';
import { EFolderStatus, IGroupsResponse } from 'src/interface/group.interface';

const useListGroup = ({
  includeEditor,
  opt
}: {
  includeEditor: boolean;
  opt?: Omit<
    UseQueryOptions<IGroupsResponse[], Error, IGroupsResponse[], QueryKey>,
    'queryKey' | 'queryFn'
  >;
}) => {
  const queryReturn = useQuery(
    [EQueryKey.ALL_GROUPS, EFolderStatus.All, includeEditor],
    getAllGroups,
    {
      ...opt,
      refetchOnMount: true,
      initialData: [],
      cacheTime: 10 * 60 * 1000
    }
  );

  return queryReturn;
};

export default useListGroup;
