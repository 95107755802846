import { useState } from 'react';

export default function useToast() {
  const [openToast, setOpenToast] = useState<{
    type: 'success' | 'error';
    content: string;
  }>({
    type: 'success',
    content: ''
  });
  const toast = (content: string, type: 'success' | 'error' = 'success') => {
    setOpenToast({ type, content });
  };
  return {
    openToast,
    toast
  };
}
