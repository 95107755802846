import { useState } from 'react';
import NotiModal from 'src/components/Modal/NotiModal';
import useModal from 'src/hooks/modal/useModal';
import { NotiContext } from './NotiContext';

export const NotiProvider: React.FC = (props) => {
  const [notiValue, setNotiValue] = useState<{
    title: string;
    content: string;
  }>({ title: '', content: '' });
  const { open, handleToggle } = useModal();

  const openNoti = (_title: string, _content: string) => {
    setNotiValue({ title: _title, content: _content });
    handleToggle();
  };
  return (
    <NotiContext.Provider value={{ openNoti }}>
      {props.children}
      <NotiModal
        open={open}
        handleToggle={handleToggle}
        content={notiValue.content}
        title={notiValue.title}
      />
    </NotiContext.Provider>
  );
};
