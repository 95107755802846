import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/sidebar/SidebarContext';

import { Box, Drawer, styled, useTheme } from '@mui/material';

import Logo from 'src/components/LogoSign';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        position: relative;
        z-index: 7;
        height: 100%;
        padding: ${theme.spacing(3, 0)};
        flex-direction: column;
        display: flex;
`
);

const LogoBoxWrapper = styled(Box)(
  ({ theme }) => `
      width: 48px;
      height: 48px;
      display: flex;
      justity-content: center;
      align-items: center;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'flex'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: theme.sidebar.background
        }}
      >
        <Box padding={theme.spacing(0, 3, 6)}>
          <LogoBoxWrapper>
            <Logo />
          </LogoBoxWrapper>
        </Box>
        <SidebarMenu />
      </SidebarWrapper>
      <Drawer
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Box padding={theme.spacing(0, 3, 6)}>
            <LogoBoxWrapper>
              <Logo />
            </LogoBoxWrapper>
          </Box>
          <SidebarMenu />
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
