import React from 'react';

import { Box, Button, ListItem, Typography, styled } from '@mui/material';

import { matchPath, useNavigate } from 'react-router-dom';
import { EPathName } from 'src/configs/pathname';
import ButtonWrapper from '../ButtonWrapper';

export const MenuItemWrapper = styled(Box)(
  ({ theme }) => `
      padding: ${theme.spacing(0, 3)};
      min-height: ${theme.sidebar.itemHeight};
      height: auto;
      
      .MuiListItem-root {
        padding: 0;
        flex: 1;
      }
`
);

interface ISidebarMenuItem {
  pathname?: EPathName | string;
  startIcon?: React.ReactNode;
  label: string;
  onClick?: () => void;
  subItem?: React.ReactElement;
}

function SidebarMenuItem({
  pathname,
  startIcon,
  label,
  onClick,
  subItem
}: ISidebarMenuItem) {
  const navigate = useNavigate();

  const isMatchPath = pathname
    ? !!matchPath(
        {
          path: pathname,
          end: true
        },
        window.location.pathname
      )
    : false;

  const handleClick = () => {
    if (pathname) {
      navigate(pathname);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <MenuItemWrapper>
      <ListItem component="div">
        <ButtonWrapper isActive={isMatchPath}>
          <Button component={'div'} onClick={handleClick} startIcon={startIcon}>
            <Typography variant="base_1_semibold">{label}</Typography>
          </Button>
        </ButtonWrapper>
      </ListItem>
      {subItem}
    </MenuItemWrapper>
  );
}

export default SidebarMenuItem;
