import { Box, Divider, Typography, useTheme } from '@mui/material';
import { openSupportZalo } from 'src/common/openSupportZalo';
import HelpLight from 'src/design/icon/outlined/HelpLight';

const Support = () => {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      sx={{
        padding: theme.spacing(0, 3),
        gap: theme.spacing(2)
      }}
    >
      <Divider
        sx={{
          backgroundColor: theme.colors.neutral[2],
          display: 'block',
          flex: 1
        }}
      ></Divider>
      <Box
        onClick={openSupportZalo}
        sx={{
          color: theme.colors.neutral[4],
          padding: theme.spacing(1.5, 0),
          flex: 1,
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          cursor: 'pointer'
        }}
        display={'flex'}
        justifyContent={'center'}
        gap={theme.spacing(1.5)}
      >
        <HelpLight />
        <Typography variant="base_1_semibold">Liên hệ hỗ trợ</Typography>
      </Box>
    </Box>
  );
};

export default Support;
