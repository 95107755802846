import { Box, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import useStrictAuthOnly from 'src/hooks/auth/useStrictAuthOnly';
import Header from './Header';
import Sidebar from './Sidebar';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  useStrictAuthOnly();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%'
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            paddingLeft: {
              lg: theme.sidebar.width,
              xs: 0
            },
            paddingTop: theme.header.height,
            width: '100%',
            minHeight: '100vh',
            height: 'auto',
            backgroundColor: theme.general.background
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
