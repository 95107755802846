export enum EPathName {
  LOGIN = '/login',
  DASHBOARDS = '/dashboards',
  NOTIFICATIONS = '/dashboards/notifications',
  MY_GROUPS = '/my-groups',
  GROUP_DETAIL = '/my-groups/:gid',
  MEMBER_DETAIL = '/my-groups/:gid/member-detail/:uid', //uid mean userId
  COURSE_DETAIL = '/my-groups/:gid/course/:cid',
  GROUP_NOTIFICATIONS = '/my-groups/:gid/notifications',
  USER_SPECIFIC_COURSE_DETAIL = '/my-groups/:gid/member-detail/:uid/course/:cid',
  RECRUITMENT_POST_DETAIL = '/my-groups/:gid/recruitment/:pid',
  STATUS = '/status',
  STATUS_404 = '/status/404',
  STATUS_403 = '/status/403',
  STATUS_500 = '/status/500'
}
