import { setCredentialsToCookies } from 'src/common/authFunction';
import {
  TAuthResponse,
  TLoginPayload,
  TRefreshPayload
} from 'src/interface/auth.interface';
import httpRequest from 'src/services/httpRequest';

export const postGetRefreshToken = (data: TRefreshPayload) =>
  httpRequest.post<TAuthResponse>(`/auth/refresh-token`, data).then((data) => {
    setCredentialsToCookies({
      accessToken: data.token,
      refreshToken: data.refreshToken,
      refreshTokenExpiryTime: data.refreshTokenExpiryTime
    });
    return data;
  });

export const postLogin = (data: TLoginPayload) =>
  httpRequest.post<TAuthResponse>(`/auth/biz-login`, data).then((data) => {
    setCredentialsToCookies({
      accessToken: data.token,
      refreshToken: data.refreshToken,
      refreshTokenExpiryTime: data.refreshTokenExpiryTime
    });
    return data;
  });
