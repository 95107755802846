import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { EPathName } from 'src/configs/pathname';
import { IMAGE_PATH } from 'src/constant/imgPath';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        display: flex;
        justify-content: center;
        align-items: center;
`
);

function Logo() {
  return (
    <LogoWrapper to={EPathName.DASHBOARDS}>
      <img src={IMAGE_PATH.NORMALIZE_LOGO} alt="doccen logo" />
    </LogoWrapper>
  );
}

export default Logo;
