import { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { EPathName } from 'src/configs/pathname';
import useListGroup from '../group/useListGroup';

const useSideBarGroupList = () => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const toggleCollapse = () => {
    setOpenCollapse((_) => !_);
  };

  const queryReturn = useListGroup({
    includeEditor: true
  });

  useEffect(() => {
    const isMatchPath = !!matchPath(
      {
        path: EPathName.GROUP_DETAIL,
        end: false
      },
      window.location.pathname
    );

    if (isMatchPath) {
      setOpenCollapse(true);
    }
  }, []);

  return {
    openCollapse,
    toggleCollapse,
    queryReturn
  };
};

export default useSideBarGroupList;
