import { Box, styled } from '@mui/material';

const ButtonWrapper = styled(Box)<{ isActive?: boolean }>(
  ({ theme, isActive = false }) => `
      width: 100%;
      height: 100%;
      flex: 1;

      .MuiButton-root{
        border-radius: 12px;
        padding: ${theme.spacing(1.5)};
        display: flex;
        width: 100%;
        justify-content: flex-start;
        text-transform: none;
        background-color: ${
          !isActive
            ? theme.sidebar.menuItemBgColor
            : theme.sidebar.menuItemBgColorActive
        };
        color: ${
          !isActive
            ? theme.sidebar.menuItemColor
            : theme.sidebar.menuItemColorActive
        };
        ${
          isActive &&
          `box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px ${theme.colors.neutral[0]};`
        }

        .MuiButton-startIcon,
        .MuiButton-endIcon {
          transition: ${theme.transitions.create(['color'])};
        }

        .MuiButton-startIcon {
          font-size: ${theme.typography.pxToRem(20)};
          margin-right: ${theme.spacing(1.75)};
        }

        &:hover {
          background-color: ${theme.sidebar.menuItemBgColorHover};
          color: ${theme.sidebar.menuItemColorHover};
          box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px ${
            theme.colors.neutral[0]
          };
        }
      }
`
);
export default ButtonWrapper;
