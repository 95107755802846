import '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';
import { EBreakpoints } from 'src/configs/breakpoints';
import { colors } from 'src/design/color/scheme';

export const PureLightTheme = createTheme({
  colors: {
    secondary: {
      '1': colors.secondary['1'],
      '2': colors.secondary['2'],
      '3': colors.secondary['3'],
      '4': colors.secondary['4'],
      '5': colors.secondary['5']
    },
    primary: {
      '1': colors.primary['1'],
      '2': colors.primary['2'],
      '3': colors.primary['3'],
      '4': colors.primary['4'],
      '5': colors.primary['5']
    },
    neutral: {
      '0': colors.neutral[0],
      '1': colors.neutral['1'],
      '2': colors.neutral['2'],
      '3': colors.neutral['3'],
      '4': colors.neutral['4'],
      '5': colors.neutral['5'],
      '6': colors.neutral['6'],
      '7': colors.neutral['7'],
      '8': colors.neutral['8']
    },
    shades: {
      'Neutral/Shades/04-75%': colors.shades['Neutral/Shades/04-75%'],
      'Neutral/Shade (dark)/04-50%':
        colors.shades['Neutral/Shade (dark)/04-50%'],
      'Neutral/Shade (dark)/04-40%':
        colors.shades['Neutral/Shade (dark)/04-40%'],
      'Neutral/Shade (dark)/08_over-07-50%':
        colors.shades['Neutral/Shade (dark)/08_over-07-50%']
    },
    action: {
      'Action/Active': colors.action['Action/Active'],
      'Action/Resting': colors.action['Action/Resting'],
      'Action/Pressed': colors.action['Action/Pressed'],
      'Action/Disabled': colors.action['Action/Disabled'],
      'Action/Disabled_background': colors.action['Action/Disabled_background'],
      'Action/Hover': colors.action['Action/Hover']
    },
    other: {
      'Outlined border-stroke': colors.other['Outlined border-stroke']
    }
  },
  breakpoints: {
    values: {
      xs: EBreakpoints.xs,
      sm: EBreakpoints.sm,
      md: EBreakpoints.md,
      lg: EBreakpoints.lg,
      xl: EBreakpoints.xl
    }
  },
  typography: {
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: 64,
      lineHeight: '100%',
      letterSpacing: '-0.03em'
    },
    h2: {
      fontWeight: 600,
      fontSize: 48,
      lineHeight: '100%',
      letterSpacing: '-0.03em'
    },
    h3: {
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '120%',
      letterSpacing: '-0.02em'
    },
    h4: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '125%',
      letterSpacing: '-0.03em'
    },
    title_1_semibold: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '160%',
      letterSpacing: '-0.02em'
    },
    title_1_medium: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '160%',
      letterSpacing: '-0.02em'
    },
    title_1_mobile: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '178%',
      letterSpacing: '-0.02em'
    },
    base_1_semibold: {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '160%',
      letterSpacing: '-0.01em'
    },
    base_1_bold: {
      fontWeight: 700,
      fontSize: 15,
      lineHeight: '160%',
      letterSpacing: '-0.01em'
    },
    base_2: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '171%',
      letterSpacing: '-0.01em'
    },
    body_1_medium: {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '160%',
      letterSpacing: '-0.015em'
    },
    body_1_semibold: {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '160%',
      letterSpacing: '-0.01em'
    },
    body_2_semibold: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '171%',
      letterSpacing: '-0.01em'
    },
    caption_1: {
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '123%',
      letterSpacing: '-0.01em'
    },
    caption_1_Medium: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '123%',
      letterSpacing: '-0.01em'
    },
    caption_2: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '133%',
      letterSpacing: '-0.01em'
    },
    caption_2_Medium: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '100%',
      letterSpacing: '-0.01em'
    },
    caption_2_Bold: {
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '133%',
      letterSpacing: '-0.01em'
    },
    button_1: {
      fontWeight: 700,
      fontSize: 15,
      lineHeight: '160%',
      letterSpacing: '-0.01em'
    },
    button_2: {
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '185%',
      letterSpacing: '-0.01em'
    }
  },
  sidebar: {
    background: colors.neutral[1],
    menuItemColor: colors.neutral[4],
    menuItemColorActive: colors.neutral[7],
    menuItemColorHover: colors.neutral[7],
    menuItemBgColor: 'transparent',
    menuItemBgColorActive: colors.neutral[3],
    menuItemBgColorHover: colors.neutral[3],
    width: '340px',
    itemHeight: '48px'
  },
  header: {
    height: '96px',
    background: colors.neutral[1]
  },
  general: {
    background: colors.neutral[2]
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          /* hide default eye UI of password input in Edge */
          /* https://stackoverflow.com/questions/61449079/how-to-hide-the-eye-from-a-password-input-in-ms-edge-and-ie */
          'input::-ms-reveal, input::-ms-clear': {
            display: 'none'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontWeight: '700',
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none'
          },
          minHeight: 0,
          borderRadius: '8px'
        },
        containedPrimary: {
          '&.Mui-disabled': {
            opacity: 0.5,
            backgroundColor: colors.primary['1'],
            color: colors.neutral['1']
          }
        }
      },
      variants: [
        {
          props: {
            variant: 'secondary',
            size: 'medium'
          },
          style: {
            padding: '12px 20px',
            border: '2px solid' + colors.neutral[3],
            backgroundColor: colors.neutral[1],
            color: colors.neutral[7],
            ':hover': {
              backgroundColor: colors.neutral[1],
              color: colors.neutral[7]
            }
          }
        },
        {
          props: {
            variant: 'secondary',
            size: 'small'
          },
          style: {
            padding: '8px 10px',
            border: '2px solid' + colors.neutral[3],
            backgroundColor: colors.neutral[1],
            color: colors.neutral[7],
            ':hover': {
              backgroundColor: colors.neutral[1],
              color: colors.neutral[7]
            }
          }
        },
        {
          props: {
            variant: 'primary',
            size: 'medium'
          },
          style: {
            padding: '12px 20px',
            backgroundColor: colors.primary[1],
            color: colors.neutral[1],
            ':hover': {
              backgroundColor: colors.primary[1],
              color: colors.neutral[1]
            }
          }
        },
        {
          props: {
            variant: 'primary',
            size: 'small'
          },
          style: {
            padding: '8px 10px',
            backgroundColor: colors.primary[1],
            color: colors.neutral[1],
            ':hover': {
              backgroundColor: colors.primary[1],
              color: colors.neutral[1]
            }
          }
        }
      ]
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementBottom: {
          backgroundColor: colors.neutral['6'],
          borderRadius: '4px',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '24px',
          letterSpacing: '-0.01rem',
          color: colors.neutral['2']
        },
        tooltipPlacementTop: {
          backgroundColor: colors.neutral['6'],
          borderRadius: '4px',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '24px',
          letterSpacing: '-0.01rem',
          color: colors.neutral['2']
        },
        tooltipPlacementRight: {
          backgroundColor: colors.neutral['6'],
          borderRadius: '4px',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '24px',
          letterSpacing: '-0.01rem',
          color: colors.neutral['2']
        },
        tooltipPlacementLeft: {
          backgroundColor: colors.neutral['6'],
          borderRadius: '4px',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '24px',
          letterSpacing: '-0.01rem',
          color: colors.neutral['2']
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordBreak: 'break-word'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          padding: '26px 24px 24px'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '32px',
          color: colors.neutral[7],
          letterSpacing: '-0.02em',
          padding: '0 0 26px'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '32px',
          letterSpacing: '-0.02em',
          color: colors.neutral[4]
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 0 24px'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '&>:not(:first-of-type)': {
            marginLeft: '16px'
          },
          padding: '24px 0 0 0'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            borderRadius: '8px'
          }
        }
      }
    }
  }
});
