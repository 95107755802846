import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { checkAuthStatus } from 'src/common/authFunction';
import { EPathName } from 'src/configs/pathname';

const useStrictAuthOnly = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    const isAuthenticated = checkAuthStatus();

    if (!isAuthenticated) {
      navigate(EPathName.LOGIN);
    }
  }, []);
};

export default useStrictAuthOnly;
