export enum EQueryKey {
  ALL_GROUPS = 'all_groups',
  PERSONAL_DATA = 'personal_data',
  GROUP_DETAIL = 'group_detail',
  COURSE_DETAIL = 'course_detail',
  PERCENT_VIEW_COURSE = 'percent_view_course',
  POST_INTERACTION = 'post_interaction',
  MEMBER_STATISTIC = 'member_static',
  COURSES_STATISTIC = 'courses_statistic',
  POST_LIST_STATISITC = 'post_list_statistic',
  COURSES_INTERACTION = 'courses_interaction',
  GROUP_NOTIFICATIONS = 'group_notifications',
  COURSES_DETAIL_STATISTIC = 'courses_detail_statistic',
  STATISITC_FOR_MEMBER_IN_GROUP = 'statistic_for_member_in_group',
  GENERAL_INFO_FOR_MEMBER_IN_GROUP = 'general_info_for_member_in_group',
  MEMBER_DETAIL = 'member_detail',
  COURSE_DETAIL_OF_MEMBER = 'course_detail_of_member',
  COURSE_PRICE = 'course_price',
  RECRUITMENT_POST = 'recruitment_post',
  RECRUITMENT_POST_DETAIL = 'recruitment_post_detail',
  USER_PLAN = 'user_plan',
  DASHBOARD_GENERAL_STATISTIC = 'dashboard_general_statistic',
  DASHBOARD_NEW_LEARNERS = 'dashboard_new_learners',
  DASHBOARD_COMPLETE_COURSE_RATIO = 'dashboard_complete_course_ratio'
}
