import { Alert, Box, Snackbar, Typography, useTheme } from '@mui/material';
import useToast from 'src/hooks/useToast';
import { ToastContext } from './ToastContext';

export const ToastProvider: React.FC = (props) => {
  const { openToast, toast } = useToast();
  const theme = useTheme();

  return (
    <ToastContext.Provider value={{ openToast, toast }}>
      {props.children}
      <Snackbar
        autoHideDuration={1500}
        disableWindowBlurListener
        open={!!openToast.content}
        onClose={() => toast('')}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          borderRadius: '0.375rem'
        }}
      >
        <Box>
          {openToast.type === 'success' ? (
            <Box
              sx={{
                backgroundColor: 'rgb(0 0 0 / 0.75)',
                padding: theme.spacing(1, 2),
                borderRadius: theme.spacing(0.5)
              }}
            >
              <Typography sx={{ color: theme.colors.neutral['1'] }}>
                {openToast.content}
              </Typography>
            </Box>
          ) : (
            <Alert severity={openToast.type}>{openToast.content}</Alert>
          )}
        </Box>
      </Snackbar>
    </ToastContext.Provider>
  );
};
