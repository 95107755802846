import { QueryFunctionContext } from '@tanstack/react-query';
import { bizEndpoint } from 'src/constant/baseApiURL';
import generatePageSize, {
  EGeneratePageSize
} from 'src/helpers/generatePageSize';
import generatePageSizeParam from 'src/helpers/generatePageSizeParam';
import {
  IEachCourseStatisticData,
  IEachMemberCourseStatisticData,
  IGeneralInfoForMemberInGroupResponse
} from 'src/interface/course.inteface';
import { IPaginationResponse } from 'src/interface/general.interface';
import { IGroupsResponse } from 'src/interface/group.interface';
import { IEachMemberManagementData } from 'src/interface/member.interface';
import httpRequest from 'src/services/httpRequest';

/** Querykey format: [key, FolderStatus, includeEditor, PageSize] */
export const getAllGroups = ({
  pageParam = 1,
  queryKey
}: QueryFunctionContext) => {
  return httpRequest.get<IGroupsResponse[]>(
    `${bizEndpoint}/groups?FolderStatus=${queryKey[1]}&includeEditor=${queryKey[2]}`
  );
};

export const getGroupDetail = (id: string) => {
  return httpRequest.get(`/v1/folders/details/${id}`);
};

/** Querykey format: [key, groupId, PageSize] */
export const getMemberStatistic = ({
  queryKey,
  pageParam = 1
}: QueryFunctionContext) => {
  return httpRequest.get<IPaginationResponse<IEachMemberManagementData>>(
    `${bizEndpoint}/groups/${queryKey[1]}/members/statistics?GroupId=${
      queryKey[1]
    }&PageNumber=${pageParam}${generatePageSizeParam(
      queryKey[2] as number | undefined,
      generatePageSize(EGeneratePageSize.MEMBER_STATISTIC)
    )}`
  );
};

/** Querykey format: [key, groupId, courseStatus, pageSize] */
export const getAllCoureseStatistiForGroup = ({
  pageParam = 1,
  queryKey
}: QueryFunctionContext) => {
  return httpRequest.get<IPaginationResponse<IEachCourseStatisticData>>(
    `${bizEndpoint}/groups/${queryKey[1]}/courses/statistics?CourseStatus=${
      queryKey[2]
    }&PageNumber=${pageParam}${generatePageSizeParam(
      queryKey[3] as number | undefined,
      generatePageSize(EGeneratePageSize.COURSES_ANALYSTS)
    )}`
  );
};

/** Querykey format: [key, groupId, userId, type, pageSize] */
export const getStatisticForMemberInGroup = ({
  pageParam = 1,
  queryKey
}: QueryFunctionContext) => {
  return httpRequest.get<IPaginationResponse<IEachMemberCourseStatisticData>>(
    `${bizEndpoint}/groups/${queryKey[1]}/members/${
      queryKey[2]
    }/courses/statistics?GroupId=${queryKey[1]}&UserId=${queryKey[2]}&Type=${
      queryKey[3]
    }&PageNumber=${pageParam}${generatePageSizeParam(
      queryKey[4] as number | undefined,
      generatePageSize(EGeneratePageSize.STATISTIC_FOR_MEMBER_IN_GROUP)
    )}`
  );
};

export const getGeneralInfoForMemberInGroup = ({
  queryKey
}: QueryFunctionContext) => {
  return httpRequest.get<IGeneralInfoForMemberInGroupResponse>(
    `${bizEndpoint}/groups/${queryKey[1]}/members/${queryKey[2]}/courses/general`
  );
};
