/** Color Scheme */

export const colors = {
  primary: {
    '1': '#2A85FF',
    '2': '#83BF6E',
    '3': '#FF6A55',
    '4': '#8E59FF',
    '5': '#F7AB17'
  },
  secondary: {
    '1': '#FFBC99',
    '2': '#CABDFF',
    '3': '#B1E5FC',
    '4': '#B5E4CA',
    '5': '#FFD88D'
  },
  /** Neutral Color Scheme */
  neutral: {
    '0': '#FFFFFF',
    '1': '#FCFCFC',
    '2': '#F4F4F4',
    '3': '#EFEFEF',
    '4': '#6F767E',
    '5': '#33383F',
    '6': '#272B30',
    '7': '#1A1D1F',
    '8': '#111315'
  },
  shades: {
    'Neutral/Shades/04-75%': '#9A9FA5',
    'Neutral/Shade (dark)/04-50%': '#6F767E',
    'Neutral/Shade (dark)/04-40%': '#6F767E66',
    'Neutral/Shade (dark)/08_over-07-50%': '#11131580'
  },
  action: {
    'Action/Active': '#707070',
    'Action/Resting': '#F1F1F1',
    'Action/Pressed': '#4B4B4B',
    'Action/Disabled': '#878787',
    'Action/Disabled_background': '#DFDFDF',
    'Action/Hover': '#E9E9E9'
  },
  other: {
    'Outlined border-stroke': '#BFBFBF4D'
  }
};
