import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getProfile } from 'src/api/personal';
import { EQueryKey } from 'src/constant/queryKey';
import { IProfileResponse } from 'src/interface/profile.interface';

const useProfile = (
  opt?: Omit<
    UseQueryOptions<IProfileResponse, Error, IProfileResponse, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const queryReturn = useQuery([EQueryKey.PERSONAL_DATA], getProfile, {
    ...opt,
    refetchOnMount: true,
    staleTime: 5 * 60 * 1000
  });

  return { queryReturn };
};

export default useProfile;
