export const IMAGE_PATH = {
  NORMALIZE_LOGO: '/normalize_logo.png',
  GOOGLE_LOGO: '/static/images/logo/google.svg',
  ZALO_LOGO: '/static/images/logo/zalo.svg',
  FLAT_DEFAULT_AVATAR: '/static/images/logo/flat-default-avatar.png',
  LESSON_COURSE_DEFAULT_THUMBNAIL:
    '/static/images/placeholders/covers/lesson-course-default-thumbnail.svg',
  QUIZ_DEFAULT_THUMBNAIL:
    '/static/images/placeholders/covers/quiz-default-thumbnail.svg'
};
