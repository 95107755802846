import { Theme } from '@mui/material';
import { PureLightTheme } from './schemes/PureLightTheme';

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      secondary: {
        '1': string;
        '2': string;
        '3': string;
        '4': string;
        '5': string;
      };
      primary: {
        '1': string;
        '2': string;
        '3': string;
        '4': string;
      };
      neutral: {
        '0': string;
        '1': string;
        '2': string;
        '3': string;
        '4': string;
        '5': string;
        '6': string;
        '7': string;
        '8': string;
      };
      shades: {
        'Neutral/Shades/04-75%': string;
        'Neutral/Shade (dark)/04-50%': string;
        'Neutral/Shade (dark)/04-40%': string;
        'Neutral/Shade (dark)/08_over-07-50%': string;
      };
      action: {
        'Action/Active': string;
        'Action/Resting': string;
        'Action/Pressed': string;
        'Action/Disabled': string;
        'Action/Disabled_background': string;
        'Action/Hover': string;
      };
      other: {
        'Outlined border-stroke': string;
      };
    };

    sidebar: {
      background: React.CSSProperties['color'];
      menuItemColor: React.CSSProperties['color'];
      menuItemColorActive: React.CSSProperties['color'];
      menuItemColorHover: React.CSSProperties['color'];
      menuItemBgColor: React.CSSProperties['color'];
      menuItemBgColorActive: React.CSSProperties['color'];
      menuItemBgColorHover: React.CSSProperties['color'];
      width: '340px';
      itemHeight: '48px';
    };
    header: {
      height: '96px';
      background: React.CSSProperties['color'];
    };
    general: {
      background: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    colors: {
      secondary: {
        '1': string;
        '2': string;
        '3': string;
        '4': string;
        '5': string;
      };
      primary: {
        '1': string;
        '2': string;
        '3': string;
        '4': string;
        '5': string;
      };
      neutral: {
        '0': string;
        '1': string;
        '2': string;
        '3': string;
        '4': string;
        '5': string;
        '6': string;
        '7': string;
        '8': string;
      };
      shades: {
        'Neutral/Shades/04-75%': string;
        'Neutral/Shade (dark)/04-50%': string;
        'Neutral/Shade (dark)/04-40%': string;
        'Neutral/Shade (dark)/08_over-07-50%': string;
      };
      action: {
        'Action/Active': string;
        'Action/Resting': string;
        'Action/Pressed': string;
        'Action/Disabled': string;
        'Action/Disabled_background': string;
        'Action/Hover': string;
      };
      other: {
        'Outlined border-stroke': string;
      };
    };

    sidebar: {
      background: React.CSSProperties['color'];
      menuItemColor: React.CSSProperties['color'];
      menuItemColorActive: React.CSSProperties['color'];
      menuItemColorHover: React.CSSProperties['color'];
      menuItemBgColor: React.CSSProperties['color'];
      menuItemBgColorActive: React.CSSProperties['color'];
      menuItemBgColorHover: React.CSSProperties['color'];
      width: '340px';
      itemHeight: '48px';
    };
    header: {
      height: '96px';
      background: React.CSSProperties['color'];
    };
    general: {
      background: React.CSSProperties['color'];
    };
  }

  interface TypographyVariants {
    title_1_semibold: React.CSSProperties;
    title_1_medium: React.CSSProperties;
    title_1_mobile: React.CSSProperties;
    base_1_semibold: React.CSSProperties;
    base_1_bold: React.CSSProperties;
    base_2: React.CSSProperties;
    body_1_medium: React.CSSProperties;
    body_1_semibold: React.CSSProperties;
    body_2_semibold: React.CSSProperties;
    caption_1: React.CSSProperties;
    caption_1_Medium: React.CSSProperties;
    caption_2: React.CSSProperties;
    caption_2_Medium: React.CSSProperties;
    caption_2_Bold: React.CSSProperties;
    button_1: React.CSSProperties;
    button_2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title_1_semibold: React.CSSProperties;
    title_1_medium: React.CSSProperties;
    title_1_mobile: React.CSSProperties;
    base_1_semibold: React.CSSProperties;
    base_1_bold: React.CSSProperties;
    base_2: React.CSSProperties;
    body_1_medium: React.CSSProperties;
    body_1_semibold: React.CSSProperties;
    body_2_semibold: React.CSSProperties;
    caption_1: React.CSSProperties;
    caption_1_Medium: React.CSSProperties;
    caption_2: React.CSSProperties;
    caption_2_Medium: React.CSSProperties;
    caption_2_Bold: React.CSSProperties;
    button_1: React.CSSProperties;
    button_2: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title_1_semibold: true;
    title_1_medium: true;
    title_1_mobile: true;
    base_1_semibold: true;
    base_1_bold: true;
    base_2: true;
    body_1_medium: true;
    body_1_semibold: true;
    body_2_semibold: true;
    caption_1: true;
    caption_1_medium: true;
    caption_2: true;
    caption_2_medium: true;
    button_1: true;
    caption_2_Bold: true;
    button_2: true;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
    primary: true;
  }
}

const themeMap: { [key: string]: Theme } = {
  PureLightTheme
};
