import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import CustomizedDialogTitle from './CustomizedDialogTitle';

export default function NotiModal({
  open,
  handleToggle,
  title = '',
  content = ''
}: {
  open: boolean;
  handleToggle: Function;
  title?: string;
  content?: string;
}) {
  return (
    <Dialog
      onClose={() => handleToggle()}
      open={open}
      aria-labelledby="noti-dialog-title"
      aria-describedby="noti-dialog-description"
    >
      <CustomizedDialogTitle
        id="noti-dialog-title"
        onClose={() => handleToggle()}
      >
        {title}
      </CustomizedDialogTitle>
      <DialogContent>
        <DialogContentText id="noti-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
