import { Box, List, styled } from '@mui/material';
import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { EPathName } from 'src/configs/pathname';
import { SidebarContext } from 'src/contexts/sidebar/SidebarContext';
import HomeLight from 'src/design/icon/outlined/HomeLight';
import useProfile from 'src/hooks/personal/useProfile';
import { USER_PLAN } from 'src/interface/plan.interface';
import SidebarMenuItem from './Button/MenuItem';
import MyGroupsList from './GroupList';
import Support from './Support';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .MuiList-root {
      padding: 0;
      
      & .MuiBox-root
      {
        margin-bottom: ${theme.spacing(1)};

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const {
    queryReturn: { data }
  } = useProfile();

  return (
    <MenuWrapper>
      <Scrollbar>
        <List sx={{ height: '100%' }}>
          {!!data?.plan && data?.plan !== USER_PLAN.Editor && (
            <SidebarMenuItem
              onClick={() => closeSidebar()}
              pathname={EPathName.DASHBOARDS}
              startIcon={<HomeLight />}
              label={'Trang chủ'}
            />
          )}
          <MyGroupsList />
          {/* <SidebarMenuItem
            onClick={() => closeSidebar()}
            pathname={EPathName.NOTIFICATIONS}
            startIcon={<NotificationLight />}
            label={'Thông báo'}
          /> */}
        </List>
      </Scrollbar>
      <Support />
    </MenuWrapper>
  );
}

export default SidebarMenu;
