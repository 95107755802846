import cookie from 'js-cookie';
import { EPathName } from 'src/configs/pathname';
import { COOKIES_AUTH_CONSTANT } from 'src/constant/auth';
import { checkNullish } from 'src/helpers/checkNullish';

export const clearCookiesAndLocalStorage = () => {
  cookie.remove(COOKIES_AUTH_CONSTANT.ACCESS_TOKEN);
  cookie.remove(COOKIES_AUTH_CONSTANT.REFRESH_TOKEN);
  localStorage.clear();
};

export const logout = () => {
  clearCookiesAndLocalStorage();
  window.location.href = EPathName.LOGIN;
};

export const setCredentialsToCookies = (data: {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpiryTime: string;
}) => {
  cookie.set(COOKIES_AUTH_CONSTANT.ACCESS_TOKEN, data.accessToken, {
    expires: new Date(data.refreshTokenExpiryTime)
  });
  cookie.set(COOKIES_AUTH_CONSTANT.REFRESH_TOKEN, data.refreshToken, {
    expires: new Date(data.refreshTokenExpiryTime)
  });
};

export const checkAuthStatus = (): boolean => {
  return !!cookie.get(COOKIES_AUTH_CONSTANT.ACCESS_TOKEN);
};

export function getAccessToken(): string {
  return checkNullish(cookie.get(COOKIES_AUTH_CONSTANT.ACCESS_TOKEN)) as string;
}

export function getRefreshToken(): string {
  return checkNullish(
    cookie.get(COOKIES_AUTH_CONSTANT.REFRESH_TOKEN)
  ) as string;
}
