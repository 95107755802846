import { createContext } from 'react';

export type ToastContextValue = {
  toast: (_content: string, _type?: 'success' | 'error') => void;
  openToast: {
    type: 'success' | 'error';
    content: string;
  };
};

export const ToastContext = createContext<ToastContextValue | undefined>(
  undefined
);
