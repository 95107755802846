import { useState } from 'react';

export default function useModal() {
  const [open, setOpen] = useState<boolean>(false);
  function handleToggle(value?: boolean) {
    if (value) {
      setOpen(value);
    } else {
      setOpen((p) => !p);
    }
  }

  return {
    open,
    handleToggle
  };
}
