import { Collapse } from '@mui/material';
import { useContext } from 'react';
import { generatePath } from 'react-router';
import { EPathName } from 'src/configs/pathname';
import { SidebarContext } from 'src/contexts/sidebar/SidebarContext';
import GroupLight from 'src/design/icon/outlined/GroupLight';
import useSideBarGroupList from 'src/hooks/sidebar/useSideBarGroupList';
import SidebarMenuItem from '../Button/MenuItem';
import SidebarSubMenuItem from '../Button/MenuItem/SidebarSubMenuItem';

function MyGroupsList() {
  const { closeSidebar } = useContext(SidebarContext);
  const {
    toggleCollapse,
    openCollapse,
    queryReturn: { data: groupList }
  } = useSideBarGroupList();

  return (
    <>
      <SidebarMenuItem
        startIcon={<GroupLight />}
        label={'Nhóm của tôi'}
        onClick={toggleCollapse}
        subItem={
          <Collapse
            sx={{
              maxHeight: '300px',
              overflowY: 'auto'
            }}
            in={openCollapse}
            timeout="auto"
            unmountOnExit
          >
            {groupList.map((item, index) => (
              <SidebarSubMenuItem
                key={item.id}
                onClick={() => closeSidebar()}
                label={item.name}
                pathname={generatePath(EPathName.GROUP_DETAIL, {
                  gid: item.id
                })}
                lastItem={index === groupList.length - 1}
              />
            ))}
          </Collapse>
        }
      />
    </>
  );
}

export default MyGroupsList;
