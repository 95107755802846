import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import { EPathName } from './configs/pathname';
import { NotiProvider } from './contexts/noti/NotiProvider';
import { ToastProvider } from './contexts/toast/ToastProvider';
import ThemeProvider from './theme/ThemeProvider';

// Create a client

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onError(error) {
              const err = error as AxiosError;
              if (err.response.status === 403) {
                navigate(EPathName.STATUS_403);
              }
            },
            retry: 0
          },
          mutations: {
            onError(error, _variables, _context) {
              const err = error as AxiosError;
              if (err.response.status === 403) {
                navigate(EPathName.STATUS_403);
              }
            }
          }
        }
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastProvider>
            <NotiProvider>
              <CssBaseline />
              {content}
            </NotiProvider>
          </ToastProvider>
        </LocalizationProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
export default App;
