import { Box, Button, ListItem, Typography, styled } from '@mui/material';
import { matchPath, useNavigate } from 'react-router-dom';
import { EPathName } from 'src/configs/pathname';
import ButtonWrapper from '../ButtonWrapper';

export const SidebarSubMenuItemWrapper = styled(Box)(
  ({ theme }) => `
      display: flex;
      align-items: center;
      height: ${theme.sidebar.itemHeight};
      margin-bottom: 0px !important;
      
      #bound-tree {
        width: ${theme.spacing(4.5)};
        height: 100%;
        padding-left: calc(${theme.spacing(3)} - 1px);
        position: relative;
        padding-right: ${theme.spacing(1.5)};
      }
      
    
      #root-line {
        height: 100%;
        width: ${theme.spacing(0.25)};
        background-color: ${theme.colors.neutral[3]};
      }
      
      #hook-line {
        position: absolute;
        top: 0;
        left: calc(${theme.spacing(3)} - 1px);
        height: 50%;
        width: ${theme.spacing(1.5)};
        border-left: ${theme.spacing(0.25)} solid;
        border-bottom: ${theme.spacing(0.25)} solid;
        border-radius: 0px 0px 0px 50%;
        border-color: ${theme.colors.neutral[3]};
        z-index: 10;
      }
      
      .MuiListItem-root {
       flex: 1;
       width: calc(100% - ${theme.spacing(4.5)})
      }
  `
);

interface ISidebarSubMenuItem {
  pathname?: EPathName | string;
  label: string;
  lastItem: boolean;
  onClick?: () => void;
}

const SidebarSubMenuItem = ({
  pathname,
  label,
  onClick,
  lastItem
}: ISidebarSubMenuItem) => {
  const navigate = useNavigate();

  const isMatchPath = pathname
    ? !!matchPath(
        {
          path: pathname,
          end: false
        },
        window.location.pathname
      )
    : false;

  const handleClick = () => {
    if (pathname) {
      navigate(pathname);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <SidebarSubMenuItemWrapper>
      <div id="bound-tree">
        {!lastItem && <div id="root-line"></div>}
        <div id="hook-line"></div>
      </div>
      <ListItem component="div">
        <ButtonWrapper isActive={isMatchPath}>
          <Button component={'div'} onClick={handleClick}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
              variant="base_1_semibold"
            >
              {label}
            </Typography>
          </Button>
        </ButtonWrapper>
      </ListItem>
    </SidebarSubMenuItemWrapper>
  );
};

export default SidebarSubMenuItem;
