import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Button,
  Popover,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useRef, useState } from 'react';
import { logout } from 'src/common/authFunction';
import { openSupportZalo } from 'src/common/openSupportZalo';
import { IMAGE_PATH } from 'src/constant/imgPath';
import useProfile from 'src/hooks/personal/useProfile';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const UserMenuButton = styled(Button)(
  ({ theme }) => `
    color: ${theme.colors.neutral[4]};
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(10)};
    width: 100%;
`
);

const StyledPopover = styled(Popover)(
  ({ theme }) => `
      .MuiPaper-root {
        box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
        border-radius: ${theme.spacing(2)};
        backdrop-filter: blur(${theme.spacing(2)});
      }
`
);

function HeaderUserbox() {
  const theme = useTheme();

  const {
    queryReturn: { data }
  } = useProfile();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="circular"
          alt={data?.fullName}
          src={data?.avatar || IMAGE_PATH.FLAT_DEFAULT_AVATAR}
        />
      </UserBoxButton>
      <StyledPopover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(2),
            borderRadius: theme.spacing(2)
          }}
        >
          <UserMenuButton fullWidth onClick={openSupportZalo}>
            <Typography
              sx={{ flex: 1, textAlign: 'left' }}
              variant="base_1_semibold"
            >
              Liên hệ hỗ trợ
            </Typography>
            <InfoOutlinedIcon />
          </UserMenuButton>
          <UserMenuButton fullWidth onClick={logout}>
            <Typography
              sx={{ flex: 1, textAlign: 'left' }}
              variant="base_1_semibold"
            >
              Đăng xuất
            </Typography>
            <LogoutIcon />
          </UserMenuButton>
        </Box>
      </StyledPopover>
    </>
  );
}

export default HeaderUserbox;
