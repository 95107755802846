import { EBreakpoints } from 'src/configs/breakpoints';

export enum EGeneratePageSize {
  PERCENT_VIEW_COURSE = 'percent_view_course',
  INTERACTION_COURSE = 'interaction_course',
  GROUP_LIST = 'group_list',
  POST_ANALYSIS = 'post_analysis',
  MEMBER_STATISTIC = 'member_static',
  COURSES_ANALYSTS = 'courses_analysts',
  POST_DETAIL = 'post_detail',
  GROUP_NOTIFICATIONS = 'group_notifications',
  LESSONS_STATISTIC = 'lessons_statistic',
  STATISTIC_FOR_MEMBER_IN_GROUP = 'static_for_member_in_group',
  GENERAL_INFO_FOR_MEMBER_IN_GROUP = 'general_info_for_member_in_group',
  POST_LIST_STATISITC = 'post_list_statistic',
  COURSE_DETAIL_OF_MEMBER = 'courses_detail_of_member',
  RECRUITMENT_POST = 'recruitment_post',
  RECRUITMENT_POST_DETAIL = 'recruitment_post_detail'
}

export default function generatePageSize(type: EGeneratePageSize) {
  const screenWidth = window.innerWidth;
  const lgMatches = EBreakpoints.md < screenWidth;
  switch (type) {
    case EGeneratePageSize.PERCENT_VIEW_COURSE:
      return lgMatches ? 12 : 6;
    case EGeneratePageSize.INTERACTION_COURSE:
      return lgMatches ? 9 : 5;
    case EGeneratePageSize.POST_ANALYSIS:
      return lgMatches ? 9 : 4;
    case EGeneratePageSize.GROUP_LIST:
      return 10;
    case EGeneratePageSize.MEMBER_STATISTIC:
      return 10;
    case EGeneratePageSize.COURSES_ANALYSTS:
      return 10;
    case EGeneratePageSize.GROUP_NOTIFICATIONS:
      return 10;
    case EGeneratePageSize.LESSONS_STATISTIC:
      return 10;
    case EGeneratePageSize.STATISTIC_FOR_MEMBER_IN_GROUP:
      return 10;
    case EGeneratePageSize.POST_LIST_STATISITC:
      return 10;
    case EGeneratePageSize.GENERAL_INFO_FOR_MEMBER_IN_GROUP:
      return 10;
    case EGeneratePageSize.COURSE_DETAIL_OF_MEMBER:
      return 10;
    case EGeneratePageSize.RECRUITMENT_POST:
      return 10;
    case EGeneratePageSize.RECRUITMENT_POST_DETAIL:
      return 10;
    default:
      return 10;
  }
}
