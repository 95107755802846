export interface IGroupsResponse {
  id: string;
  name: string;
}

export enum EFolderStatus {
  Private = 'Private',
  Public = 'Public',
  All = 'All'
}

export interface IGroupDetailResponse {
  avatar: string | null;
  cover: string | null;
  currentUserPermission: string | null;
  description: string;
  folderName: string;
  id: string;
  numOfFiles: number;
  numOfMembers: number;
  parentId: string | null;
  sharedLinkExpiredAt: string | null;
  status: EFolderStatus;
}
