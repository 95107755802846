import { useContext } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, IconButton, Tooltip, styled, useTheme } from '@mui/material';
import { SidebarContext } from 'src/contexts/sidebar/SidebarContext';

import HeaderUserbox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        padding: ${theme.spacing(0, 5)};
        right: 0;
        z-index: 6;
        background-color: ${theme.header.background};
        backdrop-filter: blur(3px);
        position: fixed;
        width: 100%;
        flex: 1;
  `
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        sx={{
          width: '100%',
          justifyContent: {
            lg: 'flex-end',
            xs: 'space-between'
          }
        }}
      >
        <Box
          component="span"
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          {/* <HeaderNotifications /> */}
          <HeaderUserbox />
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
