import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { EPathName } from './configs/pathname';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => import('src/content/pages/Login')));

// Dashboards

const Dashboard = Loader(lazy(() => import('src/content/pages/Dashboard')));
const Notifications = Loader(
  lazy(() => import('src/content/pages/Notifications'))
);
const MyGroups = Loader(lazy(() => import('src/content/pages/MyGroups')));
const GroupDetai = Loader(
  lazy(() => import('src/content/pages/MyGroups/GroupDetail'))
);
const MemberDetailPage = Loader(
  lazy(() => import('./content/pages/MyGroups/GroupDetail/MemberDetail'))
);
const CourseDetail = Loader(
  lazy(() => import('src/content/pages/MyGroups/GroupDetail/CourseDetail'))
);
const GroupNotification = Loader(
  lazy(() => import('src/content/pages/MyGroups/GroupDetail/Notification'))
);
const UserSpecificourseDetail = Loader(
  lazy(
    () =>
      import(
        'src/content/pages/MyGroups/GroupDetail/MemberDetail/UserSpecificCourseDetail'
      )
  )
);
const RecruitmentPost = Loader(
  lazy(
    () => import('./content/pages/MyGroups/GroupDetail/RecruitmentPostDetail')
  )
);
// Status

const Status403 = Loader(
  lazy(() => import('src/content/pages/Status/Status403'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={EPathName.DASHBOARDS} replace />
      },
      {
        path: EPathName.LOGIN,
        element: <Login />
      },
      {
        path: EPathName.STATUS,
        children: [
          {
            path: '',
            element: <Navigate to={EPathName.STATUS_404} replace />
          },
          {
            path: EPathName.STATUS_403,
            element: <Status403 />
          },
          {
            path: EPathName.STATUS_404,
            element: <Status404 />
          },
          {
            path: EPathName.STATUS_500,
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: EPathName.DASHBOARDS,
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      }
      // {
      //   path: EPathName.NOTIFICATIONS,
      //   element: <Notifications />
      // }
    ]
  },
  {
    path: EPathName.MY_GROUPS,
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <MyGroups />
      },
      {
        path: EPathName.GROUP_DETAIL,
        element: <GroupDetai />
      },
      {
        path: EPathName.COURSE_DETAIL,
        element: <CourseDetail />
      },
      { path: EPathName.MEMBER_DETAIL, element: <MemberDetailPage /> },
      { path: EPathName.RECRUITMENT_POST_DETAIL, element: <RecruitmentPost /> },
      {
        path: EPathName.GROUP_NOTIFICATIONS,
        element: <GroupNotification />
      },
      {
        path: EPathName.USER_SPECIFIC_COURSE_DETAIL,
        element: <UserSpecificourseDetail />
      }
    ]
  }
];

export default routes;
